/*
 * @Date: 2023-03-27 16:05:43
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-28 18:24:44
 * @FilePath: \J1-M2-QRP-CODE\components\hayhar\footer.js
 */
import React from "react";
import { addAffiliateParamToUrl } from "../../actions/util";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            end_page_list: [
                {
                    title: 'เกี่ยวกับเรา',
                    href: '/Help/?key=1',
                    hrefMobile: '/faq/',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'กฎและข้อบังคับ',
                    href: '/Help/?key=2',
                    hrefMobile: '/faq/',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'ช่วยเหลือ',
                    href: '/Help/?key=3',
                    hrefMobile: '/faq/',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'ติดต่อเรา',
                    href: '/Help/?key=4',
                    hrefMobile: '/faq/',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'ติดต่อเรา',
                    href: '',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                },
                {
                    title: 'ดาวน์โหลดแอป',
                    href: '/Download',
                    _qaq: () => {
                        global.globalGtag('jbo_QRP', 'Footer', 'footer', 'event');
                    }
                }
            ]
        }
    }

	isMobileDevice = () => {
        return typeof window !== "undefined" && window.innerWidth <= 768;
    };

    render() {
        let { end_page_list } = this.state;
        let { mainSiteUrl, affiliateUrlLM } = this.props;
        const isMobile = this.isMobileDevice();

        return (
            <>
                <div className="footer-web">
                    <div className="footer-content-web">

                        {/* 品牌赞助 */}
                        <div className="sponsorship">
                            <p>สปอนเซอร์</p>
                            <img src="/static/images/m1-001/jbo-sponsor.png" />
                        </div>
                        {/* 合作伙伴 */}
                        <div className="partner">
                            <p>ผู้จัด</p>
                            <img src="/static/images/m1-001/jbo-team-cn.png" />
                        </div>
                        {/* 支付平台 */}
                        <div className="paymentplatform">
                            <p>การเงิน</p>
                            <img src="/static/images/m1-001/jbo-pay-cn.png" />
                        </div>

                    </div>

                    <div className="footer-end_list">
                        <div className="footer-end_list-box">
                            {end_page_list.map((val, index) => {
                                // Determine the correct href based on device type
                                let href = isMobile ? val.hrefMobile : val.href;
                                // Construct the full URL based on mainSiteUrl and the affiliate URL logic
                                let fullUrl = href ? (mainSiteUrl ? mainSiteUrl + href : '') : (affiliateUrlLM ? affiliateUrlLM : '');
                                // Add the 'aff' parameter if the 'CO_affiliate' cookie is present
                                fullUrl = addAffiliateParamToUrl(fullUrl);
                                console.log("fullUrl",fullUrl)
                                return (
                                    <a key={index} target="_blank" onClick={() => { val._qaq() }} href={fullUrl} className="item">
                                        {val.title}
                                    </a>
                                );
                            })}
                        </div>

                        <span>Copyright © 2011-2021 JBO All Rights Reserved.</span>
                    </div>
                </div>

            </>
        )
    }
}

export default Footer;



