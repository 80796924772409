const initPiwik = (piwikId) => {

  global.dataLayer = global.dataLayer || [];

  global.blackbox = '';
  global.e2 = '';


  window.globalGtag = (eventCategory, action, evtName, evtValue = '') => {
    /*PiWA 追中*/
    console.log(eventCategory, action, evtName, '---------追中')
    if (eventCategory == undefined) {
      return;
    }
    let data = eventCategory.replace(/[&\|\\\*^%/$#@\-]/g, "");
    if (typeof _paq === 'object') {
      _paq.push(["trackEvent", data, action, evtName, evtValue]);
    }
  }

  const stagingEagleEyeUrl = 'https://e2.platform88798.com/E2/EagleEye.js';
  const liveEagleEyeUrl = 'https://ytl.ylyofb45n.com/E2/EagleEye.js';

  let EagleEyeUrl = '';
  console.log('global.location.host', global.location.host);
  if (
    global.location.host == 'qrpm1stag.fun88.biz' ||
    global.location.host == 'localhost:8889' ||
    global.location.host == '127.0.0.1:8889'
  ) {

    EagleEyeUrl = stagingEagleEyeUrl;

    /*PiWA 追中*/
    (function (window, document, script, dataLayer, id) {
      function stgCreateCookie(a, b, c) {
        var d = "";
        if (c) {
          var e = new Date;
          e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3), d = "; expires=" + e.toUTCString()
        }
        document.cookie = a + "=" + b + d + "; path=/"
      }

      var isStgDebug = (window.location.href.match("stg_debug") || window.document.cookie.match("stg_debug")) && !window.location.href.match("stg_disable_debug");
      stgCreateCookie("stg_debug", isStgDebug ? 1 : "", isStgDebug ? 14 : -1);
      window[dataLayer] = window[dataLayer] || [], window[dataLayer].push({
        start: (new Date).getTime(),
        event: "stg.start"
      });
      var scripts = document.getElementsByTagName(script)[0], tags = document.createElement(script),
        dl = "dataLayer" != dataLayer ? "?dataLayer=" + dataLayer : "";
      tags.async = !0, tags.src = "//analytics.ravelz.com/containers/" + id + ".js" + dl, isStgDebug && (tags.src = tags.src + "?stg_debug"), scripts.parentNode.insertBefore(tags, scripts);
      !function (a, n, i, t) {
        a[n] = a[n] || {};
        for (var c = 0; c < i.length; c++) !function (i) {
          a[n][i] = a[n][i] || {}, a[n][i].api = a[n][i].api || function () {
            var a = [].slice.call(arguments, 0), t = a;
            "string" == typeof a[0] && (t = {
              event: n + "." + i + ":" + a[0],
              parameters: [].slice.call(arguments, 1)
            }), window[dataLayer].push(t)
          }
        }(i[c])
      }(window, "ppms", ["tm", "cp", "cm"]);
    })(window, document, 'script', 'piwikDataLayer', piwikId);

    /*PiWA 追中*/

  } else {
    EagleEyeUrl = liveEagleEyeUrl;
    /*PiWA 追中*/
    (function (window, document, script, dataLayer, id) {
      function stgCreateCookie(a, b, c) {
        var d = "";
        if (c) {
          var e = new Date;
          e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3), d = "; expires=" + e.toUTCString()
        }
        document.cookie = a + "=" + b + d + "; path=/"
      }

      var isStgDebug = (window.location.href.match("stg_debug") || window.document.cookie.match("stg_debug")) && !window.location.href.match("stg_disable_debug");
      stgCreateCookie("stg_debug", isStgDebug ? 1 : "", isStgDebug ? 14 : -1);
      window[dataLayer] = window[dataLayer] || [], window[dataLayer].push({
        start: (new Date).getTime(),
        event: "stg.start"
      });
      var scripts = document.getElementsByTagName(script)[0], tags = document.createElement(script),
        dl = "dataLayer" != dataLayer ? "?dataLayer=" + dataLayer : "";
      tags.async = !0, tags.src = "//analytics.ravelz.com/containers/" + id + ".js" + dl, isStgDebug && (tags.src = tags.src + "?stg_debug"), scripts.parentNode.insertBefore(tags, scripts);
      !function (a, n, i, t) {
        a[n] = a[n] || {};
        for (var c = 0; c < i.length; c++) !function (i) {
          a[n][i] = a[n][i] || {}, a[n][i].api = a[n][i].api || function () {
            var a = [].slice.call(arguments, 0), t = a;
            "string" == typeof a[0] && (t = {
              event: n + "." + i + ":" + a[0],
              parameters: [].slice.call(arguments, 1)
            }), window[dataLayer].push(t)
          }
        }(i[c])
      }(window, "ppms", ["tm", "cp", "cm"]);
    })(window, document, 'script', 'piwikDataLayer', piwikId);

    /*PiWA 追中*/
  }


  setTimeout(() => {
    let script1 = document.createElement('script');
    script1.src = 'https://ci-mpsnare.iovation.com/snare.js';
    script1.type = 'text/javascript';
    document.querySelectorAll('body')[0].appendChild(script1);

    let script2 = document.createElement('script');
    script2.src = EagleEyeUrl;
    script2.type = 'text/javascript';
    document.querySelectorAll('body')[0].appendChild(script2);
  }, 200);

}

export default initPiwik;
