/*
 * @Date: 2023-03-27 16:05:42
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 14:07:01
 * @FilePath: \J1-M2-QRP-CODE\config\trace\index.js
 */
import traceConfigs from "./traceConfigs";
import initPiwik from "./items/piwik";
import init51la from "./items/la51";
import initGA from "./items/ga";
import affDomains from "./affDomains";



const setupTraces = (pageName) => {
  if (process.browser) {
    const thisConfig = traceConfigs[pageName];
    if (thisConfig) {
      if (thisConfig.piwik) {
        initPiwik(thisConfig.piwik);
      }
      if (thisConfig['51la']) {
        init51la(thisConfig['51la']);
      }
      if (thisConfig.ga) {
        initGA(thisConfig.ga);
      }
    }


    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
      const mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
      const aff_hardcode = affDomains[mainDomain];
      if (aff_hardcode) {
        localStorage.setItem('aff_hardcode', aff_hardcode);
      }
    }
  }
}

export default setupTraces;
