import React from "react";


class Socialbtn extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			social_list : [
				{
					icon: '/static/images/hayhar/icon-customer.png',
					text: '在线客服',
					href: 'https://cranberryapp.hihi2u.com/app',
				},{
					icon: '/static/images/hayhar/icon-weibo.png',
					text: '微博',
					href: 'http://www.weibo.com/fun88angel?is_hot=1',
				},{
					icon: '/static/images/hayhar/icon-youku.png',
					text: '优酷',
					href: 'http://i.youku.com/Fun88Bliss',
				}
			]
		}
	}
	
	render(){
		let { social_list } = this.state;
		return (
			<>
				{/* social btns */}				
				<div className={'social-web'}>
					{
						social_list.map((item , key) =>{
							return (
								<a key={key} href={item.href} className={'social-item'}>
									<img src={item.icon} />
									<span>{item.text}</span>
								</a>
							)
						})
					}
				</div>
			</>
		)
	}
}

export default Socialbtn;