/*
 * @Date: 2023-03-17 15:20:59
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-02 14:52:16
 * @FilePath: \J1-M2-QRP-CODE\config\default\banner.js
 */

export const bannerConfigs = {

    'm2-001' : [{
        cmsImageUrlWeb: '/static/images/m1-001/F1M1-desktop-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-001/F1M1-Mob.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
};