/*
 * @Date: 2023-03-15 18:06:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-03 19:41:39
 * @FilePath: \J1-M2-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 *
 */

const traceConfigs = {
    'm2-001': {
        piwik : '202ce294-adc6-4d34-a694-8a0ee3361e0e',
        "51la" : false ,
        ga : false,
    },
}








export default traceConfigs;
